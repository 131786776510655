<template>
    <div class="pageWrapper">

        <!--div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back.svg" />
                    </router-link>
                </div> 
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>Tourplus is a ecommerce mobile app focus on travel sector They sell several services which all the travel needs like hotel, airport transfer, attraction tickets, tour package and travel insurance.</p>

                <p>My task is not create a new app but revamp the older version of mobile application to a bettter interface and experience. I am also work as a brand guardian to consistent all the artwork for tourplus.</p>
                <ul class="generalUl">
                    <li>
                        <label>Involved in</label>
                        <p>UI & UX Design, Branding</p>
                    </li>
                </ul>

            </div>
        </div>
        <div class="pageRight">
            <div class="inner">
                
                <section>
                    <div class="styledHeading mb-3">
                        <h1>Logo Enhancement</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/tourplus/logo_enhancement.png">
                </section>
                
                <section>
                    <div class="styledHeading mb-3">
                        <h1>UI Kits</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/tourplus/uikit.png">
                </section>
                <section>
                    <div class="styledHeading mb-3">
                        <h1>Icon style</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/tourplus/icon.png">
                </section>


                <section class="tourplusContainer">
                    <div class="styledHeading mb-3">
                    </div>
                    <div class="screenshots">
                        <div class="item">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/screenshot_home.png">
                        </div>
                        <div class="item">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_insurance.png">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_at.png">
                        </div>
                        <div class="item">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_explore.png">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_merchant.png">
                        </div>
                        <div class="item">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_video_review.png">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_img_review.png">
                        </div>
                        <div class="item">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_profile.png">
                            <img v-preview:scope-a  src="@/assets/images/works/tourplus/screenshot_subscription.png">
                        </div>
                        <div class="item">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/screenshot_point.png">
                        </div>
                    </div>
                </section>
                
                <section>
                    <div class="styledHeading center mb-3">
                        <h1>The explore behavior</h1>
                        <p>Transform from the traditional image and text browsing experience to the trending reel style browsing experience to maximize the merchant visible rate while user browsing the content.</p>
                    </div>
                    <div class="fleximgContainer">
                        <img v-preview:scope-a src="@/assets/images/works/tourplus/explore_before.png">
                        <img v-preview:scope-a src="@/assets/images/works/tourplus/explore_after.png">
                    </div>
                </section>

                <section>
                    <div class="styledHeading center mb-3">
                        <h1>The checkout processes</h1>
                        <p>Opportunity for the customer to skip the tedious traveller details and have a quick and seamless checkout process by prefill the customer details.</p>
                    </div>
                    <div class="fleximgContainer">
                        <img v-preview:scope-a src="@/assets/images/works/tourplus/checkout_before.png">
                        <img v-preview:scope-a src="@/assets/images/works/tourplus/checkout_after.png">
                    </div>
                </section>
                
                <section>
                    <div class="styledHeading center mb-3">
                        <h1>The way to improve user stickiness</h1>
                        <p>The dropout rate old version app is high. Thus, the existing of below 2 modules is to improve the user stickiness to the mobile app. <b>Subscription plan</b> is to encourage user to spend by giving extra discount with the plan. <b>Point system</b> to stay the customer and reward them with points to redeem voucher for spending. </p>
                    </div>
                    <div class="fleximgContainer">
                        <img v-preview:scope-a src="@/assets/images/works/tourplus/point_subscription.png">
                    </div>
                </section>


                <section>
                    <div class="styledHeading center mb-3">
                        <h1>The brand guidelines</h1>
                        <p>Or you can click <a target="_blank" href="https://awkh.live/download/tourplus_guideline.pdf#toolbar=0&navpanes=0&scrollbar=0">here</a> to view the brand guideline</p>
                    </div>
                    <iframe src="https://awkh.live/download/tourplus_guideline.pdf#toolbar=0&navpanes=0&scrollbar=0" class="pdfIframe"></iframe>
                        
   
                </section>



            </div>
        </div-->
        
        <div class="workHeader tourplus">
            <div class="container">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 

                <img src="@/assets/images/works/tourplus/header_img.png" class="headerImg">

                <div class="headerContent">
                    <h1>{{pagetitle}}</h1>
                    <ul>
                        <li>UI UX Design</li>
                        <li>2021 - 2022</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="workContent">
            <section>
                <div class="container">

                    <div class="flexContainer">
                        <div class="col-8">
                            <div class="liteHeading">Overview</div>
                            <p>Tourplus is a travel-tech industry which not just e-commerce mobile app but they do have a their driver to run the travel services. They sell several services which all the travel needs like hotel, airport transfer, attraction tickets, tour package and travel insurance. My jobscope in the company is to consistent all the design elements in multiple platforms, plan and overcome the pain point.</p>
                        </div>
                        <div class="col-4 text-right">
                            <div class="liteHeading">Task</div>
                            <ul>
                                <li>UI / UX Design</li>
                                <li>UX Writing</li>
                                <li>User Research</li>
                                <li>Testing</li>
                                <li>Branding</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sectionGrey">
                <div class="container">

                    <div class="styledHeading">
                        <h2>Tourplus Lite</h2>
                    </div>
                    <p>Tourplus lite is a mobile application that I design on top of the existing tourplus mobile application or a revamp version of existing tourplus mobile application. The design existing mobile application is a little outdated and practicing the traditional experience. The tourplus lite is not a perfect product due to limited time. However, we have overcome certain problems or pain points that the customer facing before this.</p>
                    
                    <div class="liteHeading text-danger">Pain Points</div>

                    <ul>
                        <li>Too much info in home page caused no focus point</li>
                        <li>Lack of instruction</li>
                        <li>Challenging checkout process</li>
                        <li>Reviews with less impression</li>
                    </ul>
                    
                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_1.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_2.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_3.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_4.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_5.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/tourplus/app_screenshot_6.png">
                        </div>
                    </Flicking>

                    <div class="spacing mb-1"></div>
                    
                    <div class="styledHeading mb-4">
                        <h4>Iconography</h4>
                    </div>
                    <img src="@/assets/images/works/tourplus/iconography.svg">

                    <div class="spacing mb-2"></div>

                    <div class="flexContainer">
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Color Palatte</h4>
                            </div>
                            <img src="@/assets/images/works/tourplus/color_palatte.png" class="colorpalatte">
                        </div>
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Typography</h4>
                            </div>
                            <img src="@/assets/images/works/tourplus/typo.png"  class="typo">
                        </div>
                    </div>
                    
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="styledHeading">
                        <h2>Admin Dashboard</h2>
                    </div>
                    <p>I am being assigned to the merchant site and merchant app. The merchant app is targeting to the e-commerce app merchant with the main function to monitor sales and approve booking. The merchant want it to be quick and well-organized sales record with some filters.</p>
                    
                    <div class="liteHeading text-danger">Pain Points</div>

                    <ul>
                        <li>Difficult to navigate</li>
                        <li>Some data was not manageable</li>
                        <li>No sum of data record</li>
                    </ul>
                        
                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:100%">
                            <img v-preview:scope-b src="@/assets/images/works/tourplus/admin_screenshot_1.png">
                        </div>
                        <div style="min-width:100%">
                            <img v-preview:scope-b src="@/assets/images/works/tourplus/admin_screenshot_2.png">
                        </div>
                        <div style="min-width:100%">
                            <img v-preview:scope-b src="@/assets/images/works/tourplus/admin_screenshot_3.png">
                        </div>
                    </Flicking>
                    
                </div>
            </section>

            <section class="sectionGrey">
                <div class="container">
                    <div class="styledHeading">
                        <h2>Ad Hoc Design</h2>
                    </div>
                    <p>I did the marketing materials as well. This is requested from the product manager due to lack of resources and to consistent all the design for not just the product but the brand identity.</p>
                    
                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/tourplus/adhoc_1.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/tourplus/adhoc_2.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/tourplus/adhoc_3.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/tourplus/adhoc_4.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-c src="@/assets/images/works/tourplus/adhoc_5.png">
                        </div>
                    </Flicking>

                </div>
            </section>

         </div>

        <div class="footer">
            <div class="container text-center">
                
                <div class="styledHeading">
                    <h3>Let's connect</h3>
                </div>
                <p>Get in touch for opportunities or just to say hi! 👋</p>

                <div class="action">
                    <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank"><img src="@/assets/images/icon_linkedin.svg"></a>
                    <!--a href=""><img src="@/assets/images/icon_envelope.svg"></a-->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    import { Flicking } from "@egjs/vue-flicking";
    
    export default {

        components: {
            Flicking: Flicking
        },
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        name: 'tourplus',
        data () {
            return {
                pagetitle: 'Tourplus',
            }
        }
    }
</script>

<style>
    .flicking-camera{
        display: flex;
    }
    .flicking-camera > div{
        margin: 0 .5rem;
        cursor: pointer;
    }
</style>